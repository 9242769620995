import * as React from 'react'

export const Dot = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 16 16"
    {...props}>
    <circle
      cx={8}
      cy={10}
      r={8}
      fill="currentColor"
      fillRule="evenodd"
      transform="translate(0 -2)"
    />
  </svg>
)

export default Dot
