import * as React from 'react'

export const CloseLight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    height={props.height || 24}
    width={props.width || 24}
    {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M15.707 15l14.348 14.348-.707.707L15 15.707.652 30.055l-.707-.707L14.293 15-.055.652l.707-.707L15 14.293 29.348-.055l.707.707L15.707 15z"
    />
  </svg>
)

export default CloseLight
