import * as React from 'react'

export const File = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.14}
      d="M20 16.571v-8l-5.714-5.714H8.57a2.286 2.286 0 00-2.285 2.286V16.57a2.286 2.286 0 002.285 2.286h9.143A2.286 2.286 0 0020 16.571z"
      clipRule="evenodd"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.14}
      d="M6.286 5.143A2.286 2.286 0 004 7.429v11.428a2.286 2.286 0 002.286 2.286h9.143a2.286 2.286 0 002.285-2.286m-3.428-16v3.429A2.286 2.286 0 0016.57 8.57H20l-5.714-5.714z"
    />
  </svg>
)

export default File
