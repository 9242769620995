import { em, rem } from 'polished'
import styled, { css } from 'styled-components'

import { theme } from '@/theme'
import { media } from '@/utils/media'
import { p } from '@/utils/mixins'

import { HamburgerProps } from './Hamburger'

const Close = css`
  color: white;
  background: transparent;

  &:hover,
  &:focus {
    color: white;
    background: transparent;
  }
`

export const Base = styled.button<HamburgerProps>`
  padding: ${rem(13)};
  display: block;
  cursor: pointer;
  position: sticky;
  z-index: 100;

  font: inherit;
  text-transform: none;
  color: ${theme.colors.primary[500]};
  background: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  outline: none;
  width: ${rem(45)};
  height: ${rem(45)};
  transition: background 0.3s linear;
  pointer-events: all;

  &:hover,
  &:focus {
    background: white;
  }

  ${props => (props.close ? Close : '')}

  ${media.min('mobile')} {
    display: none;
  }
`

const InnerClose = css`
  transform: rotate(45deg);
  transition-delay: 0.24s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

  &::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.2s 0.24s ease-out;
  }

  &::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.44s 0.24s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
`

export const Inner = styled.div<HamburgerProps>`
  display: block;
  top: 50%;
  margin-top: -${rem(1)};
  transition-duration: 0.44s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &,
  &::before,
  &::after {
    width: ${rem(18)};
    height: ${rem(2)};
    background-color: currentColor;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: -${rem(5)};
    transition: top 0.1s 0.5s ease-in, opacity 0.2s ease-in;
  }

  &::after {
    bottom: -${rem(5)};
    transition: bottom 0.1s 0.5s ease-in, transform 0.44s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  ${props => (props.close ? InnerClose : '')}
`
