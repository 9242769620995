import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import { theme } from '@/theme'
import { media } from '@/utils/media'

export const NavBar = styled(motion.div)`
  position: relative;
  background: ${theme.colors.white};
  border-radius: 0 0 12px 12px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.space[8]};
`

export const Logo = styled.a`
  flex-shrink: 0;

  img {
    height: 2rem;
  }
`

export const Nav = styled.ul`
  display: none;
  list-style: none;
  padding: 0;

  > * {
    margin: 0 ${theme.space[2]}
  }

  a {
    color: ${theme.colors.primary[700]}
  }

  ${media.min('mobile')} {
    display: flex;
  }
`

export const NavItem = styled.li<any>`

  border-bottom: 2px solid;

  ${props => {
    if (props.isActive) {
      return css`
        font-weight: bold;
        border-color: ${theme.colors.red[500]};
      `
    } else {
      return css`
        border-color: transparent;
      `
    }
  }}
`

export const NavPrimary = styled(Nav)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSizes.xl};
  font-weight: bold;
  pointer-events: none;

  ${NavItem} {
    margin: 0 8px;
    pointer-events: all;
  }
`

export const NavSecondary = styled(Nav)`
  position: relative;
  z-index: 5;

  a {
    color: ${theme.colors.gray[400]};
  }
`
