import { IUserRole, IUser } from '@/apollo/vars/session'

export const getRoleLabel = (userRole: IUserRole): string => {
  switch (userRole) {
    case IUserRole.ADMIN:
      return 'Beheerder'
    case IUserRole.EMPLOYEE:
      return 'Medewerker'
    case IUserRole.EDITOR:
      return 'Redacteur'
  }

  throw new Error(`Missing userRole ${userRole} found.`)
}

// Reveal the first part of the email
export const getUserName = (user:IUser): string => {
  const name = user.email.split('@')[0]; 
  return name; 
}
