import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { IUser, IUserRole } from '@/apollo/vars/session'
import { Hamburger } from '@/components/common/Hamburger'
import { Menu } from '@/components/common/Menu'
import { LOGOUT, OVERVIEW, PROFILE, STATISTICS, USERS } from '@/constants/Routes'
import { MenuContext } from '@/context/MenuContext'
import useSession from '@/utils/hooks/useSession'
import { getUserName } from '@/utils/users'

import { NavBar, NavItem, NavPrimary, NavSecondary, Logo } from './Header.styled'

const getHeaderLinks = (user?: IUser) => {
  if (user === undefined) {
    return []
  }

  const links = [
    {
      to: OVERVIEW,
      label: 'Aanbod',
    },
  ]

  if (user.role === IUserRole.ADMIN || user.role === IUserRole.EDITOR) {
    links.push({
      to: USERS,
      label: 'Gebruikers',
    })

    links.push({
      to: STATISTICS,
      label: 'Statistieken',
    })
  }

  return links
}

export const AdminHeader = () => {
  const menuStatus = useContext(MenuContext)
  const session = useSession()
  const headerLinks = getHeaderLinks(session.user)
  const router = useRouter()
  const secondaryLinks = [
    {
      label: session.user ? 'Welkom, ' + getUserName(session.user) : '',
    },
    {
      to: PROFILE,
      label: 'Profiel',
    },
    {
      to: LOGOUT,
      label: 'Uitloggen',
    },
  ]

  return (
    <>
      <NavBar>
        <Logo
          href='/'
          className='logo'
        >
          <img src='/assets/images/halt.svg' />
        </Logo>
        <NavPrimary>
          {headerLinks.map(link => {
            return (
              <NavItem
                key={link.to}
                isActive={router.asPath === link.to}
              >
                <Link href={link.to}>
                  <a>{link.label}</a>
                </Link>
              </NavItem>
            )
          })}
        </NavPrimary>
        <NavSecondary>
          {session.user && <li>Welkom, {getUserName(session.user)}</li>}
          <li>
            <Link href={PROFILE}>
              <a>Profiel</a>
            </Link>
          </li>
          <li>
            <Link href={LOGOUT}>
              <a>Uitloggen</a>
            </Link>
          </li>
        </NavSecondary>
        <Hamburger onClick={() => menuStatus.setOpen()} />
      </NavBar>
      <Menu
        primaryItems={headerLinks}
        secondaryItems={secondaryLinks}
      />
    </>
  )
}
