import * as React from 'react'

export const Search = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 22 22"
    {...props}>
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth={1.5}>
      <rect width={14.5} height={14.5} x={0.75} y={0.75} rx={7.25} />
      <path d="M13.016 13.07L21 21.055" />
    </g>
  </svg>
)

export default Search
