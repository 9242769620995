import * as React from 'react'

export const Grid = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="currentColor"
      d="M9 13.2a1.8 1.8 0 011.8 1.8v4.8A1.8 1.8 0 019 21.6H4.2a1.8 1.8 0 01-1.8-1.8V15a1.8 1.8 0 011.8-1.8H9zm10.8 0a1.8 1.8 0 011.8 1.8v4.8a1.8 1.8 0 01-1.8 1.8H15a1.8 1.8 0 01-1.8-1.8V15a1.8 1.8 0 011.8-1.8h4.8zM9 2.4a1.8 1.8 0 011.8 1.8V9A1.8 1.8 0 019 10.8H4.2A1.8 1.8 0 012.4 9V4.2a1.8 1.8 0 011.8-1.8H9zm10.8 0a1.8 1.8 0 011.8 1.8V9a1.8 1.8 0 01-1.8 1.8H15A1.8 1.8 0 0113.2 9V4.2A1.8 1.8 0 0115 2.4h4.8z"
    />
  </svg>
)

export default Grid
