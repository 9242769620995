import { default as NextLink, LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useRef, ReactNode } from 'react'

import { MenuContext } from '@/context/MenuContext'

import { Base as MenuLinkBase } from './MenuLink.styled'
import { Base as SecondaryMenuLinkBase } from './SecondaryMenuLink.styled'

export type INavLink = LinkProps & {
  arrow?: boolean;
  hide?: boolean;
  children?: ReactNode;
};

export const Link = NextLink

export const MenuLink = ({ children, href }: INavLink) => {
  const menuStatus = useContext(MenuContext)
  const router = useRouter()
  let className = ''

  if (router.pathname === href) {
    className = `${className}active`
  }

  return (
    <Link href={href}>
      <MenuLinkBase
        className={className}
        onClick={() => menuStatus.setOpen()}
      >
        {children}
      </MenuLinkBase>
    </Link>
  )
}

export const SecondaryMenuLink = ({ children, href }: INavLink) => {
  const menuStatus = useContext(MenuContext)
  const router = useRouter()
  let className = ''

  if (router.pathname === href) {
    className = `${className}active`
  }

  return (
    <Link href={href}>
      <SecondaryMenuLinkBase
        className={className}
        onClick={() => menuStatus.setOpen()}
      >
        {children}
      </SecondaryMenuLinkBase>
    </Link>
  )
}
