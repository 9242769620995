import * as React from 'react'

export const CircleArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 58 58"
    {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.875 57.794a28.875 28.875 0 100-57.75 28.875 28.875 0 000 57.75zm13.38-31.427L31.427 15.54a3.61 3.61 0 00-5.104 5.104l4.667 4.667H18.047a3.61 3.61 0 100 7.218H30.99l-4.667 4.667a3.61 3.61 0 105.104 5.104l10.828-10.83a3.61 3.61 0 000-5.104z"
      clipRule="evenodd"
    />
  </svg>
)

export default CircleArrow
