import { em, rem } from 'polished'
import styled from 'styled-components'

import { media } from '@/utils/media'

export const Base = styled.a`
  display: block;
  font-weight: bold;
  color: white;
  padding: ${rem(8)} ${rem(16)};
  font-size: ${rem(24)};
  line-height: ${em(26, 24)};
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    text-decoration: underline;
    color: white;
  }

  ${media.min('mobile')} {
    font-size: ${rem(34)};
  }
`
