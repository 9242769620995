import { rem, em, rgba } from 'polished'
import styled, { css } from 'styled-components'

import { theme } from '@/theme'
import { media } from '@/utils/media'

import { IMenuBase } from './Menu'

export const Base = styled.div<IMenuBase>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 10;
  background-color: ${theme.colors.primary[500]};
  box-shadow: inset 20px 0px 10px rgba(0, 0, 0, 0.03);
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)')};

  *::selection {
    color: ${theme.colors.primary};
    text-shadow: 0 1px 0 ${rgba('black', 0.1)};
    background-color: ${rgba('white', 0.6)};
  }

  ${media.min('mobile')} {
    display: none;
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 116px 24px 20px 24px;

  ${media.min('tablet')} {
    padding: 210px 64px 20px 64px;
  }
`

export const PrimaryMenu = styled.div`
  display: block;
  margin-bottom: 16px;
`

export const SecondaryMenu = styled.div`
  display: block;
  font-size: ${rem(14)};
`
