import { SocketStatus } from '@/utils/socketWatcher'

import { makeVar } from '../utils'

export interface ISocketVar {
  status?: SocketStatus
}

export const initialState: ISocketVar = {
  status: undefined
}

const socketVar = makeVar(initialState)

export default socketVar
