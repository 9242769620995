import * as React from 'react'

export const Menu = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 50 50"
    {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.5 12.5A2.5 2.5 0 0110 10h30a2.5 2.5 0 010 5H10a2.5 2.5 0 01-2.5-2.5zm0 12.5a2.5 2.5 0 012.5-2.5h30a2.5 2.5 0 010 5H10A2.5 2.5 0 017.5 25zm0 12.5A2.5 2.5 0 0110 35h30a2.5 2.5 0 010 5H10a2.5 2.5 0 01-2.5-2.5z"
      clipRule="evenodd"
    />
  </svg>
)

export default Menu
