import * as React from 'react'

export const ArrowRightCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 1024 1024"
    {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M512 1024a512.003 512.003 0 00362.039-149.961 512.003 512.003 0 000-724.078A512.002 512.002 0 000 512a512.002 512.002 0 00512 512zm237.248-557.248l-192-192a64 64 0 00-90.496 90.496L549.504 448H320a64.003 64.003 0 00-64 64 64.003 64.003 0 0064 64h229.504l-82.752 82.752a64.003 64.003 0 0045.018 110.032 64.003 64.003 0 0045.478-19.536l192-192a64 64 0 000-90.496z"
      clipRule="evenodd"
    />
  </svg>
)

export default ArrowRightCircle
