import styled, { css, keyframes } from 'styled-components'

import { theme } from '@/theme'

import { IMainButtonProps } from './Button'

const rotatingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Base = styled.button<IMainButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${theme.space[2]} ${theme.space[3]};
  background: ${theme.colors['quaternary']['500']};
  color: ${theme.colors.white};
  font-family: ${theme.fonts.body};
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.75rem;
  text-decoration: none;
  text-align: center;
  border: none;
  cursor: pointer;
  border: none;
  border-radius: ${theme.space[1]};
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1);
  outline-style: none;
  -webkit-appearance: none;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;


  &:hover {
    background: ${theme.colors['quaternary']['600']};
  }
  &:focus {
    background: ${theme.colors['quaternary']['600']};
    outline: 2px solid ${theme.colors['quaternary']['700']};
  }

  ${props =>
    props.iconAfter &&
    css`
        flex-flow: row-reverse;
      `
  }

  ${props =>
    props.isLoading &&
    css`
      cursor: wait !important;
      user-select: none;

      svg {
        animation-name: ${rotatingAnimation};
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
      }
      `
  }

  ${props =>
    props.appearance && props.appearance == 'primary' &&
    css`
        background: ${theme.colors['quaternary']['500']};
        color: ${theme.colors.white};

        &:hover {
          background: ${theme.colors['quaternary']['600']};
        }
        &:focus {
          outline: 2px solid ${theme.colors['quaternary']['700']};
        }
      `
  }

  ${props =>
    props.appearance && props.appearance == 'secondary' &&
    css`
      background: none;
      box-shadow: inset 0 0 0 2px ${theme.colors['quaternary']['500']};
      color: ${theme.colors['quaternary']['500']};

      &:hover {
        box-shadow: inset 0 0 0 2px ${theme.colors['quaternary']['600']};
        color: ${theme.colors['quaternary']['600']};
        background: ${theme.colors['quaternary']['50']};
      }
      &:focus {
        background: ${theme.colors['quaternary']['100']};
      }
    `
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }

  ${props =>
    props.size && props.size == 'full' &&
    css`
     width: 100%;
    `
  }

  span,
  svg {
    margin: 0 ${theme.space[2]}
  }
`
