import { useReactiveVar } from '@apollo/client'

import sessionVar, { ISession, IUser } from '@/apollo/vars/session'

interface IUseSessionOutput {
  isLoggedIn: boolean
  isInitialized: boolean
  user?: IUser
}

const useSession = (): IUseSessionOutput => {
  const session: ISession = useReactiveVar(sessionVar.reactiveVar)
  const isLoggedIn = !!session.user

  return {
    isLoggedIn,
    isInitialized: session.isInitialized,
    user: session.user ?? undefined,
  }
}

export default useSession
