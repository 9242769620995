import * as React from 'react'

export const Pause = (props: React.SVGProps<SVGSVGElement>) => (

  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={props.width || 20}
    height={props.height || 20}
    fill='none'
    viewBox='0 0 20 20'
    {...props}
  >
    <path
      fill='currentColor'
      d='M15 3h-2c-.553 0-1 .048-1 .6v12.8c0 .552.447.6 1 .6h2c.553 0 1-.048 1-.6V3.6c0-.552-.447-.6-1-.6zM7 3H5c-.553 0-1 .048-1 .6v12.8c0 .552.447.6 1 .6h2c.553 0 1-.048 1-.6V3.6c0-.552-.447-.6-1-.6z'
    />
  </svg>
)

export default Pause
