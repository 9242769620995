import { ErrorProps } from '@/pages/_error'
import { DefaultLayout } from './DefaultLayout'; 

export function ErrorPage({ statusCode }: ErrorProps) {
  return (
    <DefaultLayout>
      <div>
        <h1>Oops... {statusCode ? `| ${statusCode} error` : ''} </h1>
        <p>Something went wrong</p>
      </div>
    </DefaultLayout>
  )
}
