import { DefaultTheme } from 'styled-components'

import { colors } from './colors'
import { typography } from './typography'

const space = {
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem',
  '16': '4rem',
  '20': '5rem',
  '24': '6rem',
  '32': '8rem',
  '40': '10rem',
  '48': '12rem',
  '56': '14rem',
  '64': '16rem',
}

const breakpoints: any = ['40em', '48em', '64em', '80em', '90em']

breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.xl = breakpoints[3]
breakpoints.xxl = breakpoints[4]

export const textVariants = {
  heading1: {
    fontSize: ['2rem', '3rem', '4rem'],
    color: 'black',
  },
}

export interface CustomTheme {
  breakpoints: typeof breakpoints
  fonts: typeof typography['fonts']
  fontSizes: typeof typography['fontSizes']
  colors: typeof colors
  space: typeof space
  textVariants: typeof typography['textVariants']
}

export const theme: DefaultTheme = {
  ...typography,
  breakpoints,
  space,
  colors,
}
