import * as React from 'react'

export const HeartFull = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 20"
    height={props.height || 24}
    width={props.width || 24}
    {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M11 19.297l1.21-1.195c7.311-6.39 9.29-8.77 9.29-12.124C21.5 2.886 19.085.5 15.95.5c-1.718 0-3.451.801-4.57 2.108L11 3.05l-.38-.443C9.501 1.3 7.768.5 6.05.5 2.915.5.5 2.886.5 5.978c0 3.355 1.979 5.734 9.311 12.145l1.19 1.174z"
    />
  </svg>
)

export default HeartFull
