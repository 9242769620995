import * as React from 'react'

export const Pdf = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <g fill="currentColor" clipPath="url(#prefix__prefix__clip0_497:4924)">
      <path d="M6 0a3 3 0 00-3 3v18a3 3 0 003 3h12a3 3 0 003-3V3a3 3 0 00-3-3H6zm0 1.5h12A1.5 1.5 0 0119.5 3v18a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 21V3A1.5 1.5 0 016 1.5z" />
      <path d="M6.904 18.13a1.215 1.215 0 01-.657-.63c-.292-.581-.194-1.163.12-1.652.298-.461.79-.853 1.346-1.181.705-.4 1.45-.724 2.223-.967.6-1.08 1.132-2.195 1.593-3.341a10.905 10.905 0 01-.645-1.943c-.129-.6-.178-1.194-.069-1.704.113-.53.411-1.007.975-1.234.288-.115.6-.18.903-.115a1.05 1.05 0 01.716.547c.131.246.18.534.19.807.01.28-.018.593-.07.921-.127.765-.405 1.701-.78 2.691a16.43 16.43 0 001.47 2.529 8.63 8.63 0 012 .075c.546.098 1.101.293 1.44.697.18.216.29.48.3.777.011.288-.07.573-.206.845a1.56 1.56 0 01-.532.624c-.226.148-.494.22-.765.207-.496-.021-.98-.294-1.399-.625a8.577 8.577 0 01-1.367-1.425 17.465 17.465 0 00-2.995.609c-.449.795-.96 1.552-1.531 2.265-.435.524-.912.982-1.39 1.18a1.19 1.19 0 01-.87.044zm2.069-2.851c-.249.114-.48.234-.689.357-.492.291-.811.575-.97.82-.141.218-.144.375-.06.542.015.033.03.054.039.066a.405.405 0 00.053-.018c.205-.084.532-.352.952-.858.239-.292.464-.596.675-.909zm2.46-1.995c.5-.117 1.006-.214 1.515-.29a17.512 17.512 0 01-.765-1.287 31.071 31.071 0 01-.75 1.575v.002zm3.669.675c.225.243.444.45.652.615.36.285.611.38.748.384a.16.16 0 00.105-.023.46.46 0 00.14-.187.654.654 0 00.089-.3.143.143 0 00-.039-.095c-.078-.092-.3-.227-.777-.313a5.823 5.823 0 00-.918-.08v-.001zM12.117 8.7c.126-.407.226-.822.3-1.242.046-.282.065-.514.057-.697a.92.92 0 00-.048-.298.776.776 0 00-.217.06c-.131.053-.237.16-.294.425-.06.288-.045.704.069 1.233.036.167.08.34.134.519h-.001z" />
    </g>
    <defs>
      <clipPath id="prefix__prefix__clip0_497:4924">
        <path fill="currentColor" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default Pdf
