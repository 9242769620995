import { em, rem } from 'polished'
import styled from 'styled-components'

export const Base = styled.a`
  display: block;
  color: white;
  padding: ${em(12, 16)} 1em;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    text-decoration: underline;
    color: white;
  }
`

export const Label = styled.span`
  display: block;
  color: black;
  padding: ${em(12, 16)} 1em;
`
