import { tint, shade } from 'polished'
// https://tailwindcss.com/docs/customizing-colors/#default-color-palette

const colorPalette = {
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  gray: {
    50: '#f8fafc',
    100: '#f1f5f9',
    200: '#e2e8f0',
    300: '#D1D8E3',
    400: '#97a6ba',
    500: '#64748b',
    600: '#475569',
    700: '#364152',
    800: '#27303f',
    900: '#1a202e',
  },

  red: {
    50:  tint(0.95, '#E63312'),
    100: tint(0.85, '#E63312'),
    200: tint(0.75, '#E63312'),
    300: tint(0.3, '#E63312'),
    400: tint(0.1, '#E63312'),
    500: '#E63312',
    600: shade(0.1, '#E63312'),
    700: shade(0.3, '#E63312'),
    800: shade(0.5, '#E63312'),
    900: shade(0.7, '#E63312'),
  },

  green: {
    50:  tint(0.95, '#50AF47'),
    100: tint(0.85, '#50AF47'),
    200: tint(0.75, '#50AF47'),
    300: tint(0.3, '#50AF47'),
    400: tint(0.1, '#50AF47'),
    500: '#50AF47',
    600: shade(0.1, '#50AF47'),
    700: shade(0.3, '#50AF47'),
    800: shade(0.5, '#50AF47'),
    900: shade(0.7, '#50AF47'),
  },

  blue: {
    50:  tint(0.95, '#0A86CD'),
    100: tint(0.85, '#0A86CD'),
    200: tint(0.75, '#0A86CD'),
    300: tint(0.3, '#0A86CD'),
    400: tint(0.1, '#0A86CD'),
    500: '#0A86CD',
    600: shade(0.1, '#0A86CD'),
    700: shade(0.3, '#0A86CD'),
    800: shade(0.5, '#0A86CD'),
    900: shade(0.7, '#0A86CD'),
  },

  purple: {
    50:  tint(0.95, '#9E2255'),
    100: tint(0.85, '#9E2255'),
    200: tint(0.75, '#9E2255'),
    300: tint(0.3, '#9E2255'),
    400: tint(0.1, '#9E2255'),
    500: '#9E2255',
    600: shade(0.1, '#9E2255'),
    700: shade(0.3, '#9E2255'),
    800: shade(0.5, '#9E2255'),
    900: shade(0.7, '#9E2255'),
  },

}

const palette = {
  primary: colorPalette['blue'],
  secondary: colorPalette['purple'],
  tertiary: colorPalette['green'],
  quaternary: colorPalette['red'],
}

export const colors = {
  error: '#cc0010',
  black: '#000000',
  white: '#ffffff',
  succes: '#e5fdf4',
  warning: '#fde5e5',
  neutral: '#E5F4FD',
  transparent: 'transparent',
  current: 'currentColor',
  ...palette,
  ...colorPalette,
}
