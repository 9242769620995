import * as React from 'react'

export const Poweroff = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="currentColor"
      d="M8.205 4.844a1 1 0 01.844 1.813 6.997 6.997 0 107.329 11.803 6.998 6.998 0 00-1.413-11.796 1 1 0 11.848-1.812A8.996 8.996 0 0121 13.003C21 17.973 16.97 22 12 22s-9-4.028-9-8.996a8.996 8.996 0 015.205-8.16zM12 2a1 1 0 01.993.883L13 3v7a1 1 0 01-1.993.118L11 10V3a1 1 0 011-1z"
    />
  </svg>
)

export default Poweroff
