import { ToastContainer, ToastPosition, ToastOptions } from 'react-toastify'
import styled from 'styled-components'

import { theme } from '@/theme'

const toastConfig: ToastOptions= {
  position: 'bottom-right' as ToastPosition
}

const StyledToastContainer = styled(ToastContainer).attrs(toastConfig)`
  .Toastify__toast {
    background: ${theme.colors.white};
    color: ${theme.colors.primary[700]};
    font-weight: bold;
  }


  .Toastify__toast-body {}
  .Toastify__progress-bar {
    background: ${theme.colors.tertiary[500]};
  }

  .Toastify__toast--error .Toastify__progress-bar {
    background: ${theme.colors.quaternary[500]} !important;
  }
`

export default StyledToastContainer
