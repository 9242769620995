import { SubscriptionClient } from 'subscriptions-transport-ws'

import socketVar from '@/apollo/vars/socket'

const CONNECT_CHECK_TIME = 5000

export enum SocketStatus {
  OPEN,
  CLOSE
}

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
enum SocketReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3
}

interface ICreateConnectionWatcher {
  subscriptionClient: SubscriptionClient
  onStatusUpdate: (status: SocketStatus) => void
}

function createStatusWatcher ({
  subscriptionClient,
  onStatusUpdate
}: ICreateConnectionWatcher) {
  subscriptionClient.onConnected(() => {
    onStatusUpdate(SocketStatus.OPEN)
  })

  subscriptionClient.onDisconnected(() => {
    setTimeout(() => {
      const isStillDisconnected = subscriptionClient.status as number === SocketReadyState.CLOSED

      if (!isStillDisconnected) {
        return
      }

      onStatusUpdate(SocketStatus.CLOSE)
    }, CONNECT_CHECK_TIME)
  })
}

export function createSocketWatcher (subscriptionClient: SubscriptionClient): void {
  createStatusWatcher({
    subscriptionClient,
    onStatusUpdate: (status: SocketStatus) => {
      socketVar.update(() => status)
    }
  })
}
