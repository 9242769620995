import * as React from 'react' 

import styled, { css, keyframes } from 'styled-components'

import { theme } from '@/theme'

import { Loader as IconLoading } from '@/assets/icons/components/Loader'

const rotatingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Base = styled.div`
  position: fixed; 
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.quaternary[100]};
  color: ${theme.colors.quaternary['500']};

  svg {
    animation-name: ${rotatingAnimation};
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`

export const Spinner = styled.div`
  svg {
    animation-name: ${rotatingAnimation};
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`


const Loader = () => {

    return (
      <Base><IconLoading height={32} width={32} /></Base>
    )

}

export const SpinningLoader = () => {
  return (
    <Spinner><IconLoading /></Spinner>
  )
}

export default Loader