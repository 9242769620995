import * as React from 'react'

export const Bibliotheek = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="currentColor"
      d="M15 6H9a1 1 0 00-1 1v4a1 1 0 001 1h6a1 1 0 001-1V7a1 1 0 00-1-1zm-1 4h-4V8h4v2zm3-8H5a1 1 0 00-1 1v18a1 1 0 001 1h12a3 3 0 003-3V5a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H6V4h11a1 1 0 011 1v14z"
    />
  </svg>
)

export default Bibliotheek
