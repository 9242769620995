import { DefaultLayout } from './DefaultLayout';
import { Button } from '@/components/common/Button'

interface NotFoundPageProps {
  statusCode?: number
}

const getErrorMessage = (statusCode: number) => {
  switch (statusCode) {
    case 500:
      return 'De server is momenteel niet bereikbaar. Probeer het later opnieuw.'
    default:
      return 'De pagina die je probeert te bezoeken bestaat niet (meer).'
  }
}

export function NotFoundPage({
  statusCode = 404,
}: NotFoundPageProps) {
  const handleGoBack = (statusCode: number) => () => {
    if (statusCode === 500) {
      return window.history.back()
    }

    // Make sure we go back to the page before the 404 was thrown or we'll end up getting redirected to the 404 again
    return window.history.go(-2)
  }

  return (
    <DefaultLayout>
      <div style={{ margin: 'auto'}}>
        <h1>{statusCode}</h1>
        <p>{getErrorMessage(statusCode)}</p>
        <Button
          label='Breng mij terug'
          onClick={handleGoBack(statusCode)}
        />
      </div>
    </DefaultLayout>
  )
}
