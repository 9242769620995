import * as React from 'react'

export const Smartphone = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    viewBox="0 0 24 24"
    {...props}>
    <rect width={14} height={20} x={5} y={2} rx={2} ry={2} />
    <path d="M12 18h.01" />
  </svg>
)

export default Smartphone
