import { default as NextLink } from 'next/link'
import React from 'react'

import { Icon, icons } from '@/assets/icons'

import { Base } from './Button.styled'

export const Link = NextLink

export interface IMainButtonProps {
  appearance?: 'primary' | 'secondary' | 'tertiary' | 'quaternary'
  icon?: keyof typeof icons & string
  iconAfter?: boolean
  iconOnly?: boolean
  isLoading?: boolean
  size?: 'small' | 'default' | 'large' | 'full'
  title?: string
  disabled?: boolean
  className?: string
  iconSize?: number
}

export interface ButtonProps extends IMainButtonProps {
  onClick?: (event: React.MouseEvent) => void
  type?: 'submit'
  label: string
}

export const Button = (props: ButtonProps) => {
  const {
    appearance = false,
    size = 'default',
    icon = '',
    iconAfter = false,
    iconOnly = false,
    iconSize = 24,
    isLoading = false,
    label,
    className,
    title,
    disabled,
    type,
    onClick,
  } = props

  return (
    <Base
      title={title ? title : label}
      disabled={disabled}
      type={type ? type : 'button'}
      appearance={appearance || 'quaternary'}
      isLoading={isLoading}
      iconAfter={iconAfter}
      onClick={onClick ? onClick : undefined}
      size={size}
    >
      {icon && !isLoading && <Icon
        name={icon}
        size={iconSize}
      />}
      {isLoading && <Icon name='loader' />}
      {!iconOnly && !isLoading && <span dangerouslySetInnerHTML={{ __html: label }} />}
    </Base>
  )
}
