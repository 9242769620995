import * as React from 'react'

export const Check = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    {...props}>
    <path
      fill="currentColor"
      d="M18.71 7.21a1 1 0 00-1.42 0l-7.45 7.46-3.13-3.14A1.023 1.023 0 005.29 13l3.84 3.84a1.001 1.001 0 001.42 0l8.16-8.16a1 1 0 000-1.47z"
    />
  </svg>
)

export default Check
