export const typography:any = {
  fonts: {
    heading: `"din-2014", Arial, sans-serif`,
    body: `"din-2014", sans-serif`,
  },
  fontSizes: {
    'xs': '0.75rem',
    's': '0.875rem',
    'm': '1rem',
    'lg': '1.125rem',
    'xl': '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3.5rem',
    '6xl': '4rem',
    '8xl': '4rem',
    'title': '4.5rem',
    'subtitle': '2.5rem',
  },
  textVariants: {
    'pretitle': {
      fontSizeSmall: '0.8rem',
      fontSize: '1rem',
      fontSizeXXlarge: '1rem',
      lineHeight: 1
    },
    'super': {
      fontSizeSmall: '3rem',
      fontSize: '5.25rem',
      fontSizeXXlarge: '6rem',
      lineHeight: 1
    },
    'title': {
      fontSizeSmall: '2rem',
      fontSize: '3.75rem',
      fontSizeXXlarge: '4.25rem',
      lineHeight: 0.95
    },
    'subtitle': {
      fontSizeSmall: '1.5rem',
      fontSize: '2.25rem',
      fontSizeXXlarge: '2.75rem',
      lineHeight: 1
    },
    'lead': {
      fontSizeSmall: '1.25rem',
      fontSize: '1.75rem',
      fontSizeXXlarge: '2rem',
      lineHeight: 1.2
    },
    'listitem': {
      fontSizeSmall: '1rem',
      fontSize: '1.5rem',
      fontSizeXXlarge: '1.875rem',
      lineHeight: 1.2
    },
    'default': {
      fontSizeSmall: '1rem',
      fontSize: '1.25rem',
      fontSizeXXlarge: '1.5rem',
      lineHeight: 1.2
    }
  }
}
