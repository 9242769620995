import * as React from 'react'

export const Eraser = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
    height={props.height || 24}
    width={props.width || 24}
    {...props}>
    <path
      fill="currentColor"
      d="M16.998 4.18l-3.154-2.425a2.01 2.01 0 00-2.807.365l-8.4 10.897a2.003 2.003 0 00.365 2.803l3.153 2.425a2.01 2.01 0 002.807-.365l8.401-10.897a2.003 2.003 0 00-.365-2.803zm-8.45 12.287l-.537.681a.8.8 0 01-.639.31.793.793 0 01-.485-.164l-3.153-2.425a.792.792 0 01-.303-.53.788.788 0 01.157-.589l.537-.681a.801.801 0 01.64-.311c.124 0 .309.029.485.164l3.154 2.425a.802.802 0 01.144 1.12z"
    />
  </svg>
)

export default Eraser
