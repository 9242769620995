import * as React from 'react'

export const Audio = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 64 56"
    {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.933 18h-.266A6.666 6.666 0 000 24.665v6.666A6.667 6.667 0 006.667 38h.266a6.665 6.665 0 015.064 2.327l12.14 14.166C26.15 56.84 30 55.416 30 52.322V3.676c0-3.094-3.85-4.517-5.867-2.167L12 15.67A6.667 6.667 0 016.933 18zM52.12 2.25a3.334 3.334 0 10-4.59 4.832c12.183 11.574 12.183 30.26 0 41.834a3.333 3.333 0 104.59 4.833c14.95-14.2 14.95-37.3 0-51.5zm-9.823 9.332a3.334 3.334 0 00-4.594 4.834c6.757 6.416 6.757 16.75 0 23.167a3.335 3.335 0 004.594 4.833c9.523-9.047 9.523-23.787 0-32.833z"
      clipRule="evenodd"
    />
  </svg>
)

export default Audio
