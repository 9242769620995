import { gql } from '@apollo/client'

const UserFragment = gql`
  fragment UserFragment on User {
    id
    fullName
    imageUri
    role
    email
    lastLoggedIn
    forcePasswordChange
    isMfaEnabled
    mfaDevices
  }
`

export default UserFragment
