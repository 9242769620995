import { ApolloClient, useApolloClient } from '@apollo/client'
import { useEffect } from 'react'

import session from '@/apollo/queries/session'
import sessionVar, { IUserRole } from '@/apollo/vars/session'
import { clearToken, getToken } from '@/utils/token-store'

export const getSession = async (apolloClient: ApolloClient<any>) => {
  const res = await apolloClient.query({
    query: session,
    fetchPolicy: 'no-cache',
  })

  return res?.data?.session
}

// TODO: double check.
const getIsUnauthenticatedError = (e: any) => {
  return e?.networkError?.result?.errors?.[0]?.extensions?.code === 'UNAUTHENTICATED'
}

const useSessionInitialize = () => {
  const apolloClient = useApolloClient()

  useEffect(() => {
    (async () => {
      const token = getToken()

      if (!token) {
        return sessionVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
        }))
      }

      try {
        const session = await getSession(apolloClient)

        // If the user is an admin or editor and doesn't have MFA enabled, we want to force them to enable it
        if (session?.user?.isMfaEnabled === false && (session?.user?.role === IUserRole.ADMIN || session?.user?.role === IUserRole.EDITOR)) {
          return sessionVar.update(prevState => ({
            ...prevState,
            isInitialized: true,
          }))
        }

        return sessionVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
          user: session.user,
        }))
      } catch (e) {
        const isUnauthenticatedError = getIsUnauthenticatedError(e)

        // This situation happens when the token was invalid, we wanna clear the token
        if (isUnauthenticatedError) {
          clearToken()
        }

        return sessionVar.update(prevState => ({
          ...prevState,
          isInitialized: true,
          initializationError: e,
        }))
      }
    })()
  }, [])
}

export default useSessionInitialize
