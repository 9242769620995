import { ReactNode } from 'react'

import Loader from '@/components/common/Loader/Loader'
import useSession from '@/utils/hooks/useSession'
import useSessionInitialize from '@/utils/hooks/useSessionInitialize'

interface BaseLayoutProps {
  children?: ReactNode
}

export function BaseLayout({ children }: BaseLayoutProps) {
  const session = useSession()

  useSessionInitialize()

  // TODO: replace this with something pretty.
  if (!session.isInitialized) {
    return <Loader />
  }

  return <>{children}</>
}
