import React, { useContext } from 'react'

import { MenuLink, SecondaryMenuLink } from '@/components/common/Link'
import { Label as SecondaryMenuLabel } from '@/components/common/Link/SecondaryMenuLink.styled'
import { MenuContext } from '@/context/MenuContext'

import { Base, Inner, Body, PrimaryMenu, SecondaryMenu } from './Menu.styled'

export interface IMenu {
  primaryItems?: Array<{to?: string, label: string}>;
  secondaryItems?: Array<{to?: string, label: string}>;
}

export interface IMenuBase {
  open?: boolean;
}

export const Menu = ({ primaryItems, secondaryItems }: IMenu) => {
  const menuStatus = useContext(MenuContext)

  return (
    <Base open={menuStatus.open}>
      <Inner>
        <Body>
          <PrimaryMenu>
            {primaryItems && primaryItems.map(item => {
              return (
                <React.Fragment key={item.label}>
                  {item.to ?
                    <MenuLink href={item.to}>
                      {item.label}
                    </MenuLink> : <>{item.label}</>}
                </React.Fragment>
              )
            })}
          </PrimaryMenu>
          <SecondaryMenu>
            {secondaryItems && secondaryItems.map(item => {
              return (
                <React.Fragment key={item.label}>
                  {item.to ?
                    <SecondaryMenuLink href={item.to}>
                      {item.label}
                    </SecondaryMenuLink> : <SecondaryMenuLabel>{item.label}</SecondaryMenuLabel>}
                </React.Fragment>
              )
            })}
          </SecondaryMenu>
        </Body>
      </Inner>
    </Base>
  )
}
