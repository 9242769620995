import { NetworkError } from '@apollo/client/errors'
import { toast } from 'react-toastify'

export const isNetworkError = (error: NetworkError | undefined) => {
  return error?.message === 'Failed to fetch' && error?.name === 'TypeError'
}

const NETWORK_ERROR_TOAST_ID = 'network-error'

export const networkErrorToast = () => {
  if (!toast.isActive(NETWORK_ERROR_TOAST_ID)) {
    return toast.error('De server is momenteel niet bereikbaar. Probeer het later opnieuw.', {
      toastId: NETWORK_ERROR_TOAST_ID,
    })
  }
}
