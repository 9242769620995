import { useContext } from 'react'

import { MenuContext } from '@/context/MenuContext'

import { Base, Inner } from './Hamburger.styled'

export interface HamburgerProps {
  color?: string;
  onClick: () => void;
  close?: boolean;
  className?: string;
}

export const Hamburger = ({ ...props }: HamburgerProps) => {
  const menuStatus = useContext(MenuContext)

  return (
    <Base
      {...props}
      close={menuStatus.open}
    >
      <Inner
        {...props}
        close={menuStatus.open}
      />
    </Base>
  )
}
