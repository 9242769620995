import { makeVar } from '../utils'

export enum IUserRole {
  EMPLOYEE = 'EMPLOYEE',
  EDITOR = 'EDITOR',
  ADMIN = 'ADMIN'
}

export interface IUser {
  id: string
  email: string
  fullName: string
  forcePasswordChange?: boolean
  role: IUserRole
  image: string
  lastLoggedIn: string
  isMfaEnabled?: boolean
  mfaDevices?: string[]
}

export interface ISession {
  user: IUser | null
  isInitialized: boolean
}

const initialState: ISession = {
  user: null,
  isInitialized: false
}

const sessionVar = makeVar(initialState)
export default sessionVar
