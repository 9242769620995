import * as React from 'react'

export const CircleCheck = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 59 60"
    height={props.height || 24}
    width={props.width || 24}
    {...props}>
    <path
      d="M29.5.666C13.3.666.165 13.799.165 29.999s13.133 29.334 29.333 29.334 29.334-13.134 29.334-29.334S45.699.666 29.499.666zm12.714 24.373a2.666 2.666 0 10-4.096-3.413L26.651 35.383l-5.933-5.936a2.667 2.667 0 00-3.77 3.771l8 8a2.667 2.667 0 003.933-.179z"
      fillRule="evenodd"
    />
  </svg>
)

export default CircleCheck
