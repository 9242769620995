import { normalize, rgba } from 'polished'
import { createGlobalStyle } from 'styled-components'

import { colors } from './colors'
import { theme } from './theme'

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  /* Body */

  :root {
    font-size: 16px;
    text-rendering: optimizeLegibility;

    --plyr-color-main: ${theme.colors.primary[500]};
  }

  html,
  body {
    height: 100%;
  }

  body {
    min-width: 320px;
    background: ${theme.colors.gray[100]};
    color: ${theme.colors.black};
    font-family: ${theme.fonts.body};
    text-align: left;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
  }

  /* Create a root stacking context */

  #root,
  #__next {
    isolation: isolate;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
  }

  /* Improve media defaults */

  img, picture, video, canvas, svg {
    display: block;
  }

  /* Images */

  figure {
    margin: 0;
    overflow: hidden;
  }

  svg {
    display: block;
  }

  /* Selection */

  ::selection {
    color: white;
    text-shadow: 0 1px 0 ${rgba('black', 0.1)};
    background-color: ${rgba(`${colors.primary[500]}`, 0.6)};
  }

  /* Paragraphs */

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1.25em;
    }
  }

  /* Links */

  a {
    text-decoration: none;
  }

  /* Headings */

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${theme.fonts.heading};
    font-weight: bold;

    a {
      text-decoration: none;
    }
  }

  /* Remove built-in form typography styles */

  input, button, textarea, select {
    font: inherit;
  }
`

export default GlobalStyle
