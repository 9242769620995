import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import PlausibleProvider from 'next-plausible'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'

import apolloClient from '@/apollo/apolloClient'
import ToastContainer from '@/components/common/Toast/Toast'
import { BaseLayout } from '@/components/templates'
import { PLAUSIBLE_DOMAIN } from '@/config'
import { MenuContextProvider } from '@/context/MenuContext'
import { theme } from '@/theme'
import GlobalStyle from '@/theme/GlobalStyle'
import { isBrowser } from '@/utils/isBrowser'

if (isBrowser) {
  import('@/utils/detectTouch')
  import('@/utils/detectKeyboardFocus')
}

const MyApp = ({ Component: Page, pageProps }: AppProps) => {
  return (
    <PlausibleProvider domain={PLAUSIBLE_DOMAIN}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ApolloProvider client={apolloClient}>
          <MenuContextProvider>
            <BaseLayout>
              <Page {...pageProps} />
            </BaseLayout>
            <ToastContainer />
          </MenuContextProvider>
        </ApolloProvider>
      </ThemeProvider>
    </PlausibleProvider>
  )
}

export default MyApp
