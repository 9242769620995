import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { theme } from '@/theme'
import { media } from '@/utils/media'

interface IDefaultLayout {
  children?: ReactNode;
}

const Logo = styled.a`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: ${theme.space[6]};

    span {
        display: none;
    }

    ${media.min('tablet')} {
      position: fixed;
    }
`
const Base = styled.div`
    position: relative;
    background-color: ${theme.colors.red[100]};
    min-height: 100vh;
    width: 100%;
    padding: ${theme.space[4]};
    padding-bottom: ${theme.space[24]};
    color: ${theme.colors.primary[700]};

    ${media.min('mobile')} {
        padding-bottom: ${theme.space[4]};
        display: flex;
        align-items: center;
        justify-items: center;
    }
`

export const DefaultLayout = ({ children }: IDefaultLayout) => {
  return (
    <Base>
      {children}
      <Logo
        href='https://halt.nl'
        target='_blank'
        title='Halt'
        rel='noreferrer'
      >
        <span>Halt</span>
        <img src='/assets/images/halt.svg' />
      </Logo>
    </Base>
  )
}
