import React from "react"

import Arrow from "./components/Arrow"
import ArrowLeftCircle from "./components/ArrowLeftCircle"
import ArrowRightCircle from "./components/ArrowRightCircle"
import Audio from "./components/Audio"
import Badge from "./components/Badge"
import Bibliotheek from "./components/Bibliotheek"
import Calender from "./components/Calender"
import Check from "./components/Check"
import Chevron from "./components/Chevron"
import Chevrondown from "./components/Chevrondown"
import ChevronLeft from "./components/ChevronLeft"
import ChevronRight from "./components/ChevronRight"
import Chevronup from "./components/Chevronup"
import CircleArrow from "./components/CircleArrow"
import CircleCheck from "./components/CircleCheck"
import Clock from "./components/Clock"
import CloseLight from "./components/CloseLight"
import CloseNormal from "./components/CloseNormal"
import Dot from "./components/Dot"
import Edit from "./components/Edit"
import Eraser from "./components/Eraser"
import Error from "./components/Error"
import Euro from "./components/Euro"
import File from "./components/File"
import FormatBold from "./components/FormatBold"
import FormatItalic from "./components/FormatItalic"
import FormatUnderline from "./components/FormatUnderline"
import Grid from "./components/Grid"
import Heart from "./components/Heart"
import HeartFull from "./components/HeartFull"
import Hint from "./components/Hint"
import Home from "./components/Home"
import Info from "./components/Info"
import Link from "./components/Link"
import Loader from "./components/Loader"
import Menu from "./components/Menu"
import Pause from "./components/Pause"
import Pdf from "./components/Pdf"
import Pencil from "./components/Pencil"
import Person from "./components/Person"
import Pin from "./components/Pin"
import Play from "./components/Play"
import Poweroff from "./components/Poweroff"
import Search from "./components/Search"
import Smartphone from "./components/Smartphone"
import Star from "./components/Star"
import StarFull from "./components/StarFull"
import Trash from "./components/Trash"
import Undo from "./components/Undo"
import UserPlus from "./components/UserPlus"

interface Icons {
        [key:string]: React.FC
    }
export const icons:Icons = {
"arrowLeftCircle" : ArrowLeftCircle,
"arrowRightCircle" : ArrowRightCircle,
"arrow" : Arrow,
"audio" : Audio,
"badge" : Badge,
"bibliotheek" : Bibliotheek,
"calender" : Calender,
"check" : Check,
"chevronLeft" : ChevronLeft,
"chevronRight" : ChevronRight,
"chevron" : Chevron,
"chevrondown" : Chevrondown,
"chevronup" : Chevronup,
"circleArrow" : CircleArrow,
"circleCheck" : CircleCheck,
"clock" : Clock,
"closeLight" : CloseLight,
"closeNormal" : CloseNormal,
"dot" : Dot,
"edit" : Edit,
"eraser" : Eraser,
"error" : Error,
"euro" : Euro,
"file" : File,
"formatBold" : FormatBold,
"formatItalic" : FormatItalic,
"formatUnderline" : FormatUnderline,
"grid" : Grid,
"heartFull" : HeartFull,
"heart" : Heart,
"hint" : Hint,
"home" : Home,
"info" : Info,
"link" : Link,
"loader" : Loader,
"menu" : Menu,
"pdf" : Pdf,
"pencil" : Pencil,
"person" : Person,
"pin" : Pin,
"pause" : Pause,
"play" : Play,
"poweroff" : Poweroff,
"search" : Search,
"smartphone" : Smartphone,
"starFull" : StarFull,
"star" : Star,
"trash" : Trash,
"undo" : Undo,
"userPlus" : UserPlus,
}
export interface IProps extends React.SVGProps<SVGSVGElement> {
      name: keyof typeof icons & string;
      className?: string;
      size?: number;
    }
export const Icon:React.FC <IProps> = ({ name, size, ...props } : IProps ) => {
      const IconComponent = icons[name || 'chevron']
      return (
        <IconComponent
          width={size || 24}
          height={size || 24}
          {...props}
        />
)
    }
export default Icon
