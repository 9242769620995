import * as React from 'react'

export const Undo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    data-name="Layer 37"
    viewBox="0 0 32 40"
    height={props.height || 24}
    width={props.width || 24}
    {...props}>
    <path d="M30.974 25.772l-.793-3.372A18.6 18.6 0 0013 8.029v-.573a2.427 2.427 0 00-1.409-2.192 2.593 2.593 0 00-2.771.311l-6.906 5.547a2.39 2.39 0 000 3.759l6.906 5.548a2.6 2.6 0 002.771.311A2.427 2.427 0 0013 18.548V18h.217a16.652 16.652 0 0111.156 4.281l4.958 4.461a1 1 0 001.643-.97z" />
  </svg>
)

export default Undo
