import { ApolloClient, createHttpLink, split, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { SubscriptionClient } from 'subscriptions-transport-ws'

import { API_URL, WS_URL } from '@/config'
import { isNetworkError, networkErrorToast } from '@/utils/error'
import { getSocketId } from '@/utils/socketIdStore'
import { createSocketWatcher } from '@/utils/socketWatcher'
import { getToken } from '@/utils/token-store'

if (!API_URL) {
  throw new Error(`API URL was not specified`)
}

if (!WS_URL) {
  throw new Error(`Websocket URL was not specified`)
}

const httpLink = createHttpLink({
  uri: API_URL + '/graphql'
})

const wsLink = process.browser ? new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true,
    lazy: true,
    connectionParams: {
      socketId: getSocketId()
    }
  }
}) : null

// TODO: wsLink.subscriptionClient is private, so this might break when upgrading Apollo
if (process.browser && wsLink !== null) {
  // @ts-ignore
  createSocketWatcher(wsLink.subscriptionClient as SubscriptionClient)

  // For debugging purposes
  // @ts-ignore
  window.ws = wsLink!.subscriptionClient
}

const link = process.browser ? split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink as WebSocketLink,
  httpLink,
) : httpLink

export const cache = new InMemoryCache()

const authLink = setContext((_, { headers }) => {
  const token = getToken()
  const requestHeaders = {
    ...headers
  }

  if (token) {
    requestHeaders['x-token'] = token
  }

  return {
    headers: requestHeaders
  }
})

const errorLink = onError(({ networkError }) => {
  if (isNetworkError(networkError)) {
    networkErrorToast()
  }
})

const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(link),
  cache
})

export default apolloClient
