import React, { useContext, useState, ReactNode } from 'react'

export interface MenuContextStore {
  open: boolean;
  setOpen: () => void;
}

export const MenuContext = React.createContext({} as MenuContextStore)

export const useMenu = () => {
  const ctx = useContext(MenuContext)
  if (!ctx) {
    throw Error(
      'The `useMenu` hook must be called from a descendent of the `MenuContextProvider`.',
    )
  }
  return ctx
}

interface MenuContextProviderProps {
  children: ReactNode;
}

// add MenuContextProvider to ContextProvider
export const MenuContextProvider = ({ children }: MenuContextProviderProps) => {
  const [open, setOpen] = useState(false)

  const store: MenuContextStore = {
    open: open,
    setOpen: () => setOpen(!open),
  }

  return <MenuContext.Provider value={store}>{children}</MenuContext.Provider>
}
