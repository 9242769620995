import { nanoid } from 'nanoid'

const STORAGE_NAMESPACE = 'halt-socket-id'

export const getSocketId = () => {
  const stored = localStorage.getItem(STORAGE_NAMESPACE)

  if (stored !== null) {
    return stored
  }

  const newSocketId = nanoid()
  localStorage.setItem(STORAGE_NAMESPACE, newSocketId)

  return newSocketId
}

export const clearSocketId = (): void => {
  localStorage.removeItem(STORAGE_NAMESPACE)
}
