import * as React from 'react'

export const ArrowLeftCircle = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 1024 1024"
    {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M512 1024A512.002 512.002 0 01512 0a512.002 512.002 0 01512 512 512.003 512.003 0 01-149.961 362.039A512.003 512.003 0 01512 1024zM274.752 466.752l192-192a64 64 0 0190.496 90.496L474.496 448H704a64.003 64.003 0 0164 64 64.003 64.003 0 01-64 64H474.496l82.752 82.752a64.003 64.003 0 01-45.018 110.032 64.003 64.003 0 01-45.478-19.536l-192-192a64 64 0 010-90.496z"
      clipRule="evenodd"
    />
  </svg>
)

export default ArrowLeftCircle
