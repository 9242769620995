export const LOGIN = '/inloggen'
export const LOGOUT = '/uitloggen'
export const OVERVIEW = '/aanbod'
export const USERS = '/gebruikers'
export const STATISTICS = '/statistieken'
export const USER = '/gebruikers/[userId]'
export const USERS_CREATE = `${USERS}/nieuw`
export const PROFILE = '/profiel'
export const MODULE = '/module/[slug]'
export const PREVIEW = '/module/preview/[slug]'
export const NOT_FOUND = '/404'
export const VOTE = '/stem'
export const VOTE_SESSION = '/stem/sessie/[moduleId]'
export const FORGOT_PASSWORD = '/wachtwoord-vergeten'
export const FORCE_PASSWORD = '/wachtwoord-vernieuwen'
export const CHECKIN = '/check-in/[slug]'
export const EVALUTE = '/evaluatie/[roomId]'
